import { AssetClassService } from "@/services/asset-class.service";
import { create } from "zustand";

interface IAssetClass {
  traad_classification: string;
  class: string;
  asset_type: string;
  description: string;
  fgc: string;
  ir: string;
  iof: string;
  created_by: string;
  last_updated_by: string;
  created_at: Date;
  updated_at: Date;
  id: string;
}

interface AssetClassState {
  assetClasses: IAssetClass[];
  isLoadingAssetClass: boolean;
  fetchAssetClass: () => void;
}

const useAssetClassStore = create<AssetClassState>((set) => ({
  assetClasses: [],
  isLoadingAssetClass: false,
  fetchAssetClass: async () => {
    try {
      set({ isLoadingAssetClass: true });
      const { data } = await new AssetClassService().findAll();
      set({ assetClasses: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingAssetClass: false });
    }
  },
}));

export default useAssetClassStore;
