import { LookupService } from "@/services/lookup.service";
import { create } from "zustand";
import { ILookup } from "@/services/interfaces/lookup.interface";

interface GroupState {
  groups: ILookup[];
  isLoadingGroup: boolean;
  fetchGroup: () => void;
}

const useGroupStore = create<GroupState>((set) => ({
  groups: [],
  isLoadingGroup: false,
  fetchGroup: async () => {
    try {
      set({ isLoadingGroup: true });
      const { data } = await new LookupService().findByType("group");
      set({ groups: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingGroup: false });
    }
  },
}));

export default useGroupStore;
