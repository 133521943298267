import { CertificationService } from "@/services/certifications.service";
import { ICertification } from "@/services/interfaces/certification.interface";
import { create } from "zustand";

interface CertificationsState {
  certifications: ICertification[];
  isLoadingCertifications: boolean;
  fetchCertifications: () => void;
}

const useCertificationsStore = create<CertificationsState>((set) => ({
  certifications: [],
  isLoadingCertifications: false,
  fetchCertifications: async () => {
    try {
      set({ isLoadingCertifications: true });
      const { data } = await new CertificationService().findAll();
      set({ certifications: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingCertifications: false });
    }
  },
}));

export default useCertificationsStore;
