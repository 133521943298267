/* eslint-disable import/no-anonymous-default-export */
import Dashboard from "./Dashboard";
import Pricing from "./Pricing";
import Ecommerce from "./Ecommerce";
import UserList from "./UserList";
import UserProfile from "./UserProfile";
import PersonChalkboard from "./PersonChalkboard";
import Invoice from "./Invoice";
import BadgeDollar from "./BadgeDollar";

export default {
  Dashboard,
  Pricing,
  Ecommerce,
  UserList,
  UserProfile,
  PersonChalkboard,
  Invoice,
  BadgeDollar,
};
