export enum INPUT_FEEDBACK_MESSAGES {
  REQUIRED = "Campo obrigatório",
  INVALID_EMAIL = "Email inválido",
  INVALID_PASSWORD = "Senha inválida",
  INVALID_URL = "URL inválida",
  INVALID_DATE = "Digite uma data válida",
  INVALID_NUMBER = "Digite um número válido",
  INVALID_CPF = "CPF inválido",
  INVALID_CNPJ = "CNPJ inválido",
  DUPLICATE_EMAIL = "Email já cadastrado",
  DUPLICATE_ACRONYM = "Sigla já cadastrada",
  DUPLICATE_CPF = "CPF já cadastrado",
  DUPLICATE_CNPJ = "CNPJ já cadastrado",
  DUPLICATE_ASSET = "Ativo já cadastrado",
  EXPIRATION_DATE_BEFORE_START_DATE = "Data de expiração deve ser maior que a data de início",
}
