import { CircularProgress } from "@mui/material";
import React from "react";

interface LoadingIndicatorProps {
  size?: "small" | "medium" | "large";
}

const LoadingIndicator = ({ size = "small" }: LoadingIndicatorProps) => {
  const sizeMap = {
    small: 20,
    medium: 40,
    large: 70,
  };

  return <CircularProgress size={sizeMap[size]} />;
};

export default LoadingIndicator;
