import React, { useEffect } from "react";
import useAssetClassStore from "./useAssetClasses";
import useCertificationStore from "./useCertificationStore";
import useDepartmentStore from "./useDepartments";
import useGenderStore from "./useGenderStore";
import useGroupStore from "./useGroupsStore";
import useMaritalStatusStore from "./useMaritalStatusStore";
import useOccupationStore from "./useOccupationStore";
import useBankStore from "./useBankStore";
import useIssuerStore from "./useIssuersStore";

export interface InitStoresProps {
  children: React.ReactNode;
}

const InitStores = ({ children }: InitStoresProps) => {
  const { fetchGender } = useGenderStore();
  const { fetchMaritalStatus } = useMaritalStatusStore();
  const { fetchCertifications } = useCertificationStore();
  const { fetchOccupation } = useOccupationStore();
  const { fetchGroup } = useGroupStore();
  const { fetchDepartment } = useDepartmentStore();
  const { fetchAssetClass } = useAssetClassStore();
  const { fetchBank } = useBankStore();
  const { fetchIssuer } = useIssuerStore();

  useEffect(() => {
    Promise.all([
      fetchGender(),
      fetchMaritalStatus(),
      fetchCertifications(),
      fetchOccupation(),
      fetchGroup(),
      fetchDepartment(),
      fetchAssetClass(),
      fetchBank(),
      fetchIssuer(),
    ]).catch((error) => {
      console.error(error);
    });
  }, []);

  return <>{children}</>;
};

export default InitStores;
