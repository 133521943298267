import {
  ReactNode,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

import { destroyCookie, parseCookies, setCookie } from "nookies";

import { traadApi } from "../config/api";
import { AuthService } from "@/services/auth.service";

interface User {
  id: string;
  roles: string[];
  active: boolean;
  email: string;
  owner: {
    full_name: string;
  };
}

interface AuthContextData {
  isAuthenticated: boolean;
  user?: User;
  signIn: (token: string) => Promise<string>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  signOut: () => void;
}

interface AuthContextProvider {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

export const AuthProvider = ({ children }: AuthContextProvider) => {
  const [user, setUser] = useState<User>();
  const isAuthenticated = !!user;
  const authService = new AuthService();

  const loadUser = async () => {
    const response = await authService.loadUser();
    const userData = response.data;
    setUser(userData);
  };

  const saveToken = (token: string) => {
    setCookie(undefined, "traad.token", token, {
      maxAge: 60 * 60 * 1, // 1 hour
      path: "/",
    });
  };

  const signIn = async (token: string): Promise<string> => {
    console.log("signIn");
    saveToken(token);
    traadApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    await loadUser();
    return token;
  };

  const signOut = () => {
    console.log("signOut");
    setUser(undefined);
    destroyCookie(undefined, "traad.token", {
      path: "/",
    });
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, setUser, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
