import LoadingIndicator from "@/components/LoadingIndicator";
import { Backdrop } from "@mui/material";
import React from "react";

const AppLoading = () => {
  return (
    <Backdrop
      open={true}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <LoadingIndicator size="large" />
    </Backdrop>
  );
};

export default AppLoading;
