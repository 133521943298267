import { LookupService } from "@/services/lookup.service";
import { create } from "zustand";
import { ILookup } from "@/services/interfaces/lookup.interface";

interface GenderState {
  genders: ILookup[];
  isLoadingGender: boolean;
  fetchGender: () => void;
}

const useGenderStore = create<GenderState>((set) => ({
  genders: [],
  isLoadingGender: false,
  fetchGender: async () => {
    try {
      set({ isLoadingGender: true });
      const { data } = await new LookupService().findByType("gender");
      set({ genders: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingGender: false });
    }
  },
}));

export default useGenderStore;
