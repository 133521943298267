import duotone from "@/icons/duotone";

export const NAVIGATION_MENU_ITEMS = [
  {
    name: "VISÃO GERAL",
    icon: duotone.Dashboard,
    path: "/",
  },
  {
    name: "MOVIMENTAÇÕES",
    icon: duotone.Pricing,
    children: [
      { name: "Lista de Movimentações", path: "/transactions" },
      { name: "Cadastro de Movimentações", path: "/transactions/register" },
      { name: "Caixa Cliente", path: "/transactions/cashier/register" },
      { name: "Validador de extrato", path: "/transactions/validator" },
    ],
  },
  {
    name: "ATUALIZAÇÕES DE PREÇOS",
    icon: duotone.BadgeDollar,
    children: [
      { name: "Atualização diária de preços", path: "/price/register" },
    ],
  },

  {
    name: "PRODUTOS",
    icon: duotone.Ecommerce,
    children: [
      { name: "Listagem de Produtos", path: "/products" },
      { name: "Cadastro de Produto", path: "/products/register" },
      { name: "Atualização de Preços", path: "/dashboard/account-v2" },
      { name: "Atualização de Índices", path: "/dashboard/account-v2" },
      { name: "Cadastro de Classe de Ativos", path: "/dashboard/account-v2" },
    ],
  },
  {
    name: "CLIENTES",
    icon: duotone.UserList,
    children: [
      { name: "Lista de Clientes", path: "/client" },
      { name: "Cadastro de Cliente", path: "/client/register" },
      { name: "Cadastro de Contrato", path: "/client/contract/register" },
      {
        name: "Cadastro de Conta do Cliente",
        path: "/client/account/register",
      },
      {
        name: "Cadastro Perfil do Investidor",
        path: "/client/profile/register",
      },
      { name: "Cadastro de KYC", path: "/client/kyc/register" },
    ],
  },
  {
    name: "CONSULTORES",
    icon: duotone.PersonChalkboard,
    children: [
      { name: "Lista de Consultores", path: "/consultant" },
      { name: "Cadastro de Consultores", path: "/consultant/register" },
    ],
  },
  {
    name: "COLABORADORES",
    icon: duotone.Invoice,
    children: [
      { name: "Lista de Colaboradores", path: "/employee" },
      { name: "Cadastro de Colaboradores", path: "/employee/register" },
    ],
  },

  {
    name: "USUÁRIOS",
    icon: duotone.UserProfile,
    children: [
      { name: "Lista de Usuários", path: "/user" },
      { name: "Cadastro de Usuários", path: "/user/register" },
    ],
  },
];
