import { create } from "zustand";

import { IIssuers } from "@/services/interfaces/issuer.interface";
import { IssuersService } from "@/services/issuers.service";

interface IssuerState {
  issuers: IIssuers[];
  isLoadingIssuer: boolean;
  fetchIssuer: () => void;
}

const useIssuerStore = create<IssuerState>((set) => ({
  issuers: [],
  isLoadingIssuer: false,
  fetchIssuer: async () => {
    try {
      set({ isLoadingIssuer: true });
      const { data } = await new IssuersService().findAll();
      set({ issuers: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingIssuer: false });
    }
  },
}));

export default useIssuerStore;
