import { LookupService } from "@/services/lookup.service";
import { create } from "zustand";
import { ILookup } from "@/services/interfaces/lookup.interface";

interface OccupationState {
  occupations: ILookup[];
  isLoadingOccupation: boolean;
  fetchOccupation: () => void;
}

const useOccupationStore = create<OccupationState>((set) => ({
  occupations: [],
  isLoadingOccupation: false,
  fetchOccupation: async () => {
    try {
      set({ isLoadingOccupation: true });
      const { data } = await new LookupService().findByType("occupation");
      set({ occupations: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingOccupation: false });
    }
  },
}));

export default useOccupationStore;
