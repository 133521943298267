import { Router } from "next/router";
import { AuthProvider } from "@/contexts/AuthContext";
import createEmotionCache from "@/utils/createEmotionCache";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import type { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SettingsProvider from "@/contexts/settingsContext";
import { theme } from "@/theme";
import { useEffect, useState } from "react";
import AppLoading from "@/layouts/AppLoading";
import InitStores from "@/stores/InitStores";
import { Toaster } from "react-hot-toast";

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function App({
  Component,
  pageProps,
  emotionCache = createEmotionCache(),
}: MyAppProps) {
  const [loading, setLoading] = useState(false);

  const handleRouteChangeStart = () => {
    setLoading(true);
  };

  const handleRouteChangeComplete = () => {
    setLoading(false);
  };

  const handleRouteChangeError = () => {
    setLoading(false);
  };

  useEffect(() => {
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    Router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      Router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>TRAAD | Backoffice</title>
      </Head>
      <ThemeProvider theme={theme}>
        <Toaster />
        <SettingsProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <QueryClientProvider client={new QueryClient()}>
                <CssBaseline />
                {loading && <AppLoading />}
                <InitStores>
                  <Component {...pageProps} />
                </InitStores>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </AuthProvider>
          </LocalizationProvider>
        </SettingsProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
