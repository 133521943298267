import { LookupService } from "@/services/lookup.service";
import { create } from "zustand";
import { ILookup } from "@/services/interfaces/lookup.interface";

interface DepartmentState {
  departments: ILookup[];
  isLoadingDepartment: boolean;
  fetchDepartment: () => void;
}

const useDepartmentStore = create<DepartmentState>((set) => ({
  departments: [],
  isLoadingDepartment: false,
  fetchDepartment: async () => {
    try {
      set({ isLoadingDepartment: true });
      const { data } = await new LookupService().findByType("department");
      set({ departments: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingDepartment: false });
    }
  },
}));

export default useDepartmentStore;
