import { LookupService } from "@/services/lookup.service";
import { create } from "zustand";
import { ILookup } from "@/services/interfaces/lookup.interface";

interface MaritalStatusState {
  maritalStatuses: ILookup[];
  isLoadingMaritalStatus: boolean;
  fetchMaritalStatus: () => void;
}

const useMaritalStatusStore = create<MaritalStatusState>((set) => ({
  maritalStatuses: [],
  isLoadingMaritalStatus: false,
  fetchMaritalStatus: async () => {
    try {
      set({ isLoadingMaritalStatus: true });
      const { data } = await new LookupService().findByType("marital_status");
      set({ maritalStatuses: data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingMaritalStatus: false });
    }
  },
}));

export default useMaritalStatusStore;
