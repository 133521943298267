import { create } from "zustand";
import { BanksService } from "@/services/banks.service";
import { IBanks } from "@/services/interfaces/banks.interface";

interface BankState {
  banks: IBanks[];
  isLoadingBank: boolean;
  fetchBank: () => void;
}

const useBankStore = create<BankState>((set) => ({
  banks: [],
  isLoadingBank: false,
  fetchBank: async () => {
    try {
      set({ isLoadingBank: true });
      const { data } = await new BanksService().findAll();
      set({ banks: data.data });
    } catch (error) {
      console.error(error);
    } finally {
      set({ isLoadingBank: false });
    }
  },
}));

export default useBankStore;
